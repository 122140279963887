import scrollIntoView from 'scroll-into-view-if-needed';
import { Theme } from './Theme';
import { NavProjects } from './NavProjects';
import { throttle } from 'lodash';

/* faux jQuery Helpers */
const $ = (sel) => document.querySelector(sel);
const $$ = (sel) => document.querySelectorAll(sel);

const theme = new Theme();

// const isDebug = process.env.NODE_ENV !== 'production';
const isDebug = false;
const debug = document.createElement('div');

/* window state */
const reachedBottom = (offset, limit) => offset >= limit;
const reachedTop = (offset) => offset <= 0;

const isPortrait = window.matchMedia('(orientation: portrait)').matches;
const isArchive = document.documentElement.classList.contains('archive');

const THRESHOLD = new Array(101).fill(0).map((zero, index) => index * 0.01);

/**
 *  Set up hit zone
 */
const getIntersectionZone = (node) => {
  const tH = node
    .querySelector('.project-nav--title')
    .getBoundingClientRect().height;
  const wH = window.innerHeight;

  if (isPortrait && isArchive) {
    return {
      top: Math.abs(tH) * -1,
      bottom: Math.abs(tH) - wH + tH
    };
  } else {
    return {
      top: tH / 3 - wH / 2,
      bottom: tH / 3 - wH / 2
    };
  }
};

/**
 *  Scroller
 */
export class Scroller {
  constructor(nodelist, infinite = true) {
    this.nav = new NavProjects(nodelist);
    this.projects = nodelist;
    this.context = nodelist[0]?.parentElement;
    this.zone = getIntersectionZone($('.project-nav--item'));
    this.scrollPos = window.pageYOffset;
    this.infinite = infinite;
    this.observing = false;

    this.scrolling = false;
    this.mY = 0;

    if (this.infinite === true) this.cloneItems();
    this.init();
  }

  set observer(nodelist) {
    this._observer = new IntersectionObserver(this.observerHandler.bind(this), {
      threshold: THRESHOLD,
      rootMargin: `${this.zone.top}px 0px ${this.zone.bottom}px`
    });

    this._observer.disconnect();

    if (isDebug) {
      document.documentElement.style.setProperty(
        '--zoneTop',
        `${this.zone.top}px`
      );
      document.documentElement.style.setProperty(
        '--zoneBottom',
        `${this.zone.bottom}px`
      );
    }
  }

  get observer() {
    return this._observer;
  }

  async prepareObserver() {
    this.projects.forEach((el) => this.observer.observe(el));
    return true;
  }

  async observe() {
    const observer = await this.prepareObserver();
    if (observer === true) {
      this.observing = true;
      $('.loader').style.opacity = 0;
    }
  }

  get clonesHeight() {
    const clones = this.clones;
    const height = clones.reduce(
      (prev, curr) => prev + parseInt(curr.clientHeight),
      0
    );

    return height;
  }

  set scrollPos(pos) {
    window.scrollTo(0, pos);
  }

  get scrollPos() {
    return window.pageYOffset;
  }

  get limit() {
    return this.context.scrollHeight - window.innerHeight;
  }

  cloneItems() {
    const active = this.projects[0];
    const parent = active.parentElement;
    const siblings = [...parent.children];
    const index = siblings.indexOf(active);

    const start = siblings.slice(0, index);
    const end = siblings.slice(index);

    const clones = [start, end].map((arr) => {
      let fragment = document.createDocumentFragment();

      arr
        .map((el) => el.cloneNode(true))
        .map((clone) => {
          const wrapper = clone.querySelector('.project-nav--wrapper');

          clone.classList.add('clone');
          clone.classList.remove('active');
          clone.removeAttribute('id');

          if (wrapper) wrapper.remove();

          fragment.appendChild(clone);
        });

      return fragment;
    });

    parent.insertBefore(clones[1], active);
    parent.appendChild(clones[0]);

    $$('.clone .project-nav--title').forEach((clone) => {
      clone.addEventListener('click', this.nav);
      clone.addEventListener('mouseover', this.nav);
    });

    this.clones = clones;
    this.projects = [...parent.children];
  }

  scrollToFirst() {
    const first = $('.project-nav--item:not(.clone) .project-nav--title');
    scrollIntoView(first, {
      behavior: 'instant',
      block: 'center',
      inline: 'center'
    });
  }

  handleEvent(event) {
    this['on' + event.type](event);
  }

  onresize() {
    this.observer.disconnect();
    this.zone = getIntersectionZone($('.project-nav--item'));
    this.observer = this.projects;
  }

  onscroll() {
    if (!this.observing) {
      this.observe();
    }

    const y = this.scrollPos;
    const limit = this.limit;

    if (reachedBottom(y, limit)) {
      this.scrollPos = 1;
    } else if (reachedTop(y)) {
      this.scrollPos = limit - 1;
    }
  }

  toggleActiveProject(el) {
    theme.setTheme(el);
    this.nav.toggle(el);
  }

  observerHandler(entries, observer) {
    entries
      .filter((entry) => entry.isIntersecting && entry.intersectionRatio > 0.33)
      .forEach((entry) => {
        this.toggleActiveProject(entry.target);

        if (isDebug) {
          const currProject = entry;
          let visiblePct = Math.floor(entry.intersectionRatio * 100) + '%';

          console.log('top ' + entry.rootBounds.top);
          console.log('bottom ' + entry.rootBounds.bottom);
          console.log('width ' + entry.rootBounds.width);
          console.log('height ' + entry.rootBounds.height);

          debug.style.width = entry.rootBounds.width + 'px';
          debug.style.height = entry.rootBounds.height + 'px';
          debug.style.top = entry.rootBounds.top + 'px';
          debug.style.bottom = entry.rootBounds.bottom + 'px';

          currProject.target.querySelector('.rect').innerHtml = visiblePct;

          debug.textContent = `${
            currProject.target.querySelector('.project-nav--title').textContent
          } ${visiblePct}`;

          document.documentElement.style.setProperty(
            '--intersectionRectTop',
            `${currProject.intersectionRect.top}px`
          );

          document.documentElement.style.setProperty(
            '--intersectionRectBottom',
            `${currProject.intersectionRect.bottom}px`
          );

          document.documentElement.style.setProperty(
            '--intersectionRectLeft',
            `${currProject.intersectionRect.left}px`
          );

          document.documentElement.style.setProperty(
            '--intersectionRectRight',
            `${currProject.intersectionRect.right}px`
          );

          document.documentElement.style.setProperty(
            '--intersectionRectWidth',
            `${currProject.intersectionRect.width}px`
          );

          document.documentElement.style.setProperty(
            '--intersectionRectHeight',
            `${currProject.intersectionRect.height}px`
          );
        }
      });
  }

  throttledResize() {
    throttle(() => this.onresize.bind(this), 200);
  }

  init() {
    this.observer = this.projects;

    window.addEventListener('resize', this.throttledResize, {
      passive: true
    });

    if (this.infinite === true) {
      const addScrollEvent = (e) => {
        e.currentTarget.removeEventListener('animationend', addScrollEvent);
        window.addEventListener('scroll', this, {
          passive: true
        });
      };

      $('.loader').addEventListener('animationend', addScrollEvent);
    }

    jQuery(() => {
      this.scrollToFirst();
    });

    if (isDebug) {
      document.documentElement.appendChild(debug);
      debug.classList = 'debug';

      [...this.projects].forEach((project) => {
        const div = document.createElement('div');
        div.classList.add('rect');
        project.querySelector('h2').appendChild(div);
      });
    }
  }

  destroy(instance) {
    window.removeEventListener('scroll', this, {
      passive: true
    });
    window.removeEventListener('resize', this.throttledResize, {
      passive: true
    });

    this.observer.disconnect();

    delete this.onresize;
    delete this.onscroll;
    delete this.handleEvent;
    delete this.observer;
    delete this.observerHandler.bind(this);

    $$('.clone').forEach((c) => c.remove());

    this.nav.players.forEach((p) => {
      p.destroy();
    });

    $('.wrapper').style.height = '';
  }
}
