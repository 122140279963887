/* faux jQuery Helpers */
const $ = sel => document.querySelector(sel);
const $$ = sel => document.querySelectorAll(sel);

export class Theme {
  constructor() {
    this.doc = document.documentElement;
  }

  getIndex(node) {
    return typeof node === 'string' ? $(`[data-index="${node}"]`) : node;
  }

  setTheme(node) {
    const { bgcolor, textcolor } = this.getIndex(node).dataset;

    if (bgcolor && textcolor) {
      this.doc.style.setProperty('--projectBg', bgcolor);
      this.doc.style.setProperty('--projectText', textcolor);
    }
  }

  replaceHistory(node) {
    const { title, url } = this.getIndex(node).dataset;

    if (title && url) {
      window.history.replaceState({}, `${title} &mdash; Millten`, url);
    }
  }
}
