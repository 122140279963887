import { Theme } from './Theme';
import { YoutubeAPI, Player } from './Youtube';

const theme = new Theme();

/* faux jQuery Helpers */
const $ = (sel) => document.querySelector(sel);
const $$ = (sel) => document.querySelectorAll(sel);

export class NavProjects {
  constructor(nodelist) {
    this.projects = nodelist;
    this.players = [];

    this.init();
  }

  set players(array) {
    this._players = array;
  }

  get players() {
    return this._players;
  }

  handleEvent(event) {
    this['on' + event.type](event);
  }

  onclick(event) {
    this.navigate(event);
  }

  ontouchend(event) {
    const project = event.target.closest('.project-nav--item');

    if (!project.classList.contains('active')) {
      this.toggle(event.currentTarget);

      project.scrollIntoView({
        behavior: 'smooth'
      });
    } else {
      this.navigate(event);
    }
  }

  onmouseover(event) {
    this.toggle(event.currentTarget);
  }

  onmouseenter(event) {
    $('html').classList.add('js-project-nav-hover');
  }

  onmouseleave(event) {
    $('html').classList.remove('js-project-nav-hover');
  }

  toggle(el) {
    const i = el.closest('.project-nav--item').dataset.index;
    const currProject = $$(`[data-index="${i}"]`);
    const prevProjects = Array.from($$('.active'));

    theme.setTheme(currProject[0]);

    if (prevProjects.length > 0) {
      prevProjects
        .filter((p) => p !== currProject)
        .forEach((p) => {
          requestAnimationFrame(() => {
            p.classList.remove('active');
            const parent = p.closest('.project-nav--item');

            if (parent.dataset.video) {
              this.players.forEach((player) => {
                const pv = player._player;

                if (pv && pv.getPlayerState() === 1) pv.pauseVideo();
              });
            }
          });
        });
    }

    currProject.forEach((p) => {
      const title = p.querySelector('.project-nav--title');
      const wrapper = p.querySelector('.project-nav--wrapper');

      requestAnimationFrame(() => {
        if (title) title.classList.add('active');
        if (wrapper) wrapper.classList.add('active');

        if (p.dataset.video) {
          this.players.forEach((player) => {
            const p = player._player;

            if (p && (p.getPlayerState() !== 1 || p.ready)) p.playVideo();
          });
        }
      });
    });
  }

  navigate(event) {
    const project = event.target.closest('.project-nav--item');
    const target = project.querySelector('a').getAttribute('target');

    if (!target || target !== '_blank') {
      event.preventDefault();

      if (project.dataset.slug) {
        project.removeEventListener('click', this);
        window.location.href = project.dataset.slug;
      }
    }
  }

  init() {
    const hash = window.location.hash;
    const wrapper = $('.wrapper');
    const paddingTop = parseInt(window.getComputedStyle(wrapper).paddingTop);

    if (hash) {
      const currProject = $(hash);

      $('.project-nav--item.active').classList.remove('active');
      currProject.classList.add('active');

      theme.setTheme(currProject);

      window.scroll({
        top: currProject.getBoundingClientRect().top + paddingTop
      });
    }

    this.projects.forEach((project) => {
      project
        .querySelector('.project-nav--title')
        .addEventListener('touchend', this, { passive: true });

      project
        .querySelector('.project-nav--title')
        .addEventListener('mouseover', this);

      project
        .querySelector('.project-nav--title')
        .addEventListener('click', this);

      project
        .querySelector('.project-nav--link')
        .addEventListener('click', this);
    });

    const projectsList = this.projects[0].parentElement;

    projectsList.addEventListener('mouseenter', this);
    projectsList.addEventListener('mouseleave', this);

    const videos = [...this.projects].filter(
      (project) => project.dataset.video
    );

    if (videos.length > 0) {
      YoutubeAPI.init();
      this.players = videos.map((video) => new Player(video));
    }
  }
}
