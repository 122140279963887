/**
 *  Mobile Scroller
 */
/* faux jQuery Helpers */
const $ = sel => document.querySelector(sel);
const $$ = sel => document.querySelectorAll(sel);

export class MobileScroller {
  constructor(nodelist, delay) {
    this.projects = [...nodelist];
    this.context = this.projects[0].parentElement;
    this.delay = delay;
    this.activeProject = this.projects[0];

    this.init();
  }

  toggleProjects() {
    const total = this.projects.length;
    const index = this.projects.indexOf(this.activeProject);
    const isLast = index === total - 1;
    const isFirst = index === 0;

    const curr = isLast ? this.projects[0] : this.projects[index];

    const prev = isFirst ? this.projects[total - 1] : this.projects[index - 1];

    const next = this.projects.indexOf(curr) + 1;

    requestAnimationFrame(() => {
      prev.classList.remove('active');
      curr.classList.add('active');
    });

    this.activeProject = this.projects[next];
  }

  init() {
    // reset
    $$('.project-nav--item.active').forEach(p => p.classList.remove('active'));

    this.projects = this.projects.filter(p => !p.dataset.video);
    this.activeProject = this.projects[0];
    this.interval = setInterval(() => this.toggleProjects(), this.delay);
  }

  destroy() {
    clearInterval(this.interval);
    delete this.interval;
  }
}
