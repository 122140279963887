/* global YT */
import { throttle } from 'lodash';

const isHome = document.body.classList.contains('home');

const PLAYER_API_URL = 'https://www.youtube.com/player_api';
const PLAYERDEFAULTS = {
  autohide: 1,
  autoplay: 0,
  controls: 0,
  disablekb: 1,
  enablejsapi: 1,
  fs: 0,
  iv_load_policy: 3,
  modestbranding: 1,
  playsinline: 1,
  origin: process.env.ORIGIN,
  rel: 0,
  showinfo: 0
};

window.onYouTubePlayerAPIReady = () => {
  const event = new Event('YouTubePlayerAPIReady');
  window.dispatchEvent(event);
};

export const YoutubeAPI = {
  init: () => {
    if (window.YT === undefined) {
      const tag = document.createElement('script');

      tag.src = PLAYER_API_URL;
      tag.type = 'text/javascript';
      tag.defer = true;

      document.body.append(tag);
    }
  }
};

export class Player {
  constructor(node) {
    this.el = node;
    this.id = node.dataset.video;
    this.ready = false;

    this.init();
  }

  set container(node) {
    let fragment = document.createDocumentFragment();
    let container = document.createElement('div');
    let frame = document.createElement('div');
    let index = node.dataset.index;

    container.className = 'item--video';
    container.id = `player-${index}`;

    frame.className = 'player';

    container.appendChild(frame);
    fragment.appendChild(container);

    this.iframe = this.render(fragment);
  }

  get container() {
    return this.iframe;
  }

  set player(player) {
    if (player) {
      this._player = player;
    }
  }

  get player() {
    return this._player;
  }

  render(fragment) {
    const img = this.el.querySelector('.project-image');
    const link = this.el.querySelector('.project-nav--link');

    if (img) {
      img.parentElement.insertBefore(fragment, img);
    } else if (link) {
      link.appendChild(fragment);
    } else {
      this.el.appendChild(fragment);
    }
    return this.el.querySelector('.player');
  }

  play() {
    this.player.playVideo();
  }

  pause() {
    this.player.pauseVideo();
  }

  toggle(node) {
    const currId = node.dataset.video;
    const isActive = this.id === currId;

    if (isActive) {
      this.player.playVideo();
    }

    if (!isActive) {
      this.player.pauseVideo();
    }
  }

  toggleAudio() {
    const p = this.player;

    if (p.isMuted()) {
      this.iframe.parentElement.classList.remove('is-muted');
      p.unMute();
    }

    if (!p.isMuted()) {
      p.mute();
      this.iframe.parentElement.classList.add('is-muted');
    }
  }

  handleEvent(event) {
    this['on' + event.type](event);
  }

  onclick(event) {
    event.preventDefault();
    this.toggleAudio();
  }

  onresize(width, height) {
    const p = this.player;
    const container = this.iframe.parentElement;
    const ratio = parseFloat(this.el.dataset.ratio) || 9 / 16;

    let w = container.getBoundingClientRect().width;
    let h = w / (1 + ratio);
    p.setSize(w, h);
  }

  onPlayerReady(event) {
    const p = this.player;
    this.ready = true;

    this.iframe = p.getIframe();
    p.mute();

    if (isHome) {
      p.loadVideoById(this.id);
      this.pause();
      if (this.el.classList.contains('active')) {
        this.play();
      }
    } else {
      p.loadVideoById(this.id);
    }

    this.iframe.classList.add('js-ready');

    this.onresize();
  }

  onPlayerStateChange(event) {
    switch (event.data) {
      case YT.PlayerState.PLAYING:
        break;
      case YT.PlayerState.PAUSED:
        break;
      case YT.PlayerState.ENDED:
        this.player.playVideo();
        break;
      default:
        break;
    }
  }

  throttledResize() {
    throttle(() => this.onresize.bind(this), 200);
  }

  onYouTubePlayerAPIReady(event) {
    this.player = new YT.Player(this.container, {
      playerVars: PLAYERDEFAULTS
    });

    this.player.addEventListener('onReady', this.onPlayerReady.bind(this));

    this.player.addEventListener(
      'onStateChange',
      this.onPlayerStateChange.bind(this)
    );

    this.player.getIframe().parentElement.addEventListener('click', this);

    window.addEventListener('resize', this.throttledResize, {
      passive: true
    });
  }

  init() {
    window.addEventListener('YouTubePlayerAPIReady', this);
    this.container = this.el;
  }

  destroy() {
    window.removeEventListener('resize', this.throttledResize, {
      passive: true
    });
    this.iframe.parentElement.removeEventListener('click', this);
    this.el.querySelectorAll('.item--video').forEach((i) => i.remove());
  }
}
