/* faux jQuery Helpers */
const $ = sel => document.querySelector(sel);
const $$ = sel => document.querySelectorAll(sel);

export class NavPrimary {
  constructor(el) {
    this.el = el;
    this.drawer = this.el.querySelector('[role=menu]');
    this.navtoggle = this.el.querySelector('.nav-toggle');
    this.html = $('html');
    this.wrapper = $('main.wrapper');
    this.visibile = false;

    this.init();
  }

  handleEvent(event) {
    this['on' + event.type](event);
  }

  onclick(event) {
    this.toggle();
  }

  onanimationend() {
    let el = this.drawer;

    // el.classList.remove('navIn', 'navOut');
    this.wrapper.classList.remove('wrapperIn', 'wrapperOut');

    el.hasAttribute('hidden')
      ? el.removeAttribute('hidden')
      : el.setAttribute('hidden', '');

      this.drawer.removeEventListener('animationend', this);
  }

  show() {
    let el = this.drawer;

    this.drawer.addEventListener('animationend', this);
    el.classList.remove('navOut');
    el.classList.add('navIn');

    this.wrapper.classList.add('wrapperIn');
    this.html.classList.add('js-nav-open');
    this.html.classList.remove('js-nav-close');

    this.visible = true;
  }

  hide() {
    let el = this.drawer;

    this.drawer.addEventListener('animationend', this);
    el.classList.remove('navIn');
    el.classList.add('navOut');

    this.wrapper.classList.add('wrapperOut');
    this.html.classList.remove('js-nav-open');
    this.html.classList.add('js-nav-close');

    this.visible = false;
  }

  toggle() {
    this.navtoggle.checked ? this.show() : this.hide();
  }

  init() {
    this.html.classList.add('js-nav-close');
    this.navtoggle.addEventListener('click', this);
  }
}
