/* Project dependencies */
import '../scss/app.scss';

import fitvids from 'fitvids';
import objectFitImages from 'object-fit-images';

/* Navigation, Youtube */
import { NavPrimary } from './NavPrimary.js';
import { YoutubeAPI, Player } from './Youtube';
import { Scroller } from './ProjectScroller';
import { MobileScroller } from './MobileScroller';
import { debounce } from 'lodash';

/* faux jQuery Helpers */
const $ = (sel) => document.querySelector(sel);
const $$ = (sel) => document.querySelectorAll(sel);

/*
 * Configuration
 */
const MOBILE_PROJECT_DELAY = 666;

if (process.env.NODE_ENV === 'production') {
  objectFitImages();
}

const projects = $$('.project-nav--item');
const nav = $('.nav-primary');
const loaderani = setTimeout(() => {
  hideLoader();
  Millten();
}, 3000);

let scroller;
let initialized = false;

/**
 * initialize Mobile or Desktop homepage script
 */
function loadScroller() {
  if (
    window.matchMedia('(orientation: portrait) and (max-width: 60rem)').matches
  ) {
    if (scroller instanceof Scroller) {
      scroller.destroy(scroller);
      scroller = null;
    }

    scroller = new MobileScroller(projects, MOBILE_PROJECT_DELAY);
  } else {
    if (scroller instanceof MobileScroller) {
      scroller.destroy(scroller);
      scroller = null;
    }

    if (projects.length) {
      scroller = new Scroller(projects);
    }
  }
}

/**
 * Start the app
 */
function Millten() {
  if (!initialized) {
    fitvids();
    const navprimary = new NavPrimary(nav);

    if ($('.home')) {
      loadScroller();
    }

    if ($('.archive') && projects.length) {
      scroller = new Scroller(projects, false);
    }

    if ($('.page.single')) {
      const page = $('.item');

      if (page.dataset.video) {
        YoutubeAPI.init();

        const player = new Player(page);
      }
    }

    initialized = true;
  }
}

function hideLoader() {
  clearTimeout(loaderani);
  document.documentElement.classList.remove('loading');
  document.documentElement.classList.add('complete');
}

function deferLoading() {
  const imgDefer = $$('.unloaded');
  const hidePlaceholder = (el) => el.classList.remove('unloaded');

  imgDefer.forEach((i) => {
    i.addEventListener('load', (e) => hidePlaceholder(e.currentTarget));

    if (i.getAttribute('data-src')) {
      i.setAttribute('src', i.getAttribute('data-src'));
    }

    if (i.getAttribute('data-srcset')) {
      i.setAttribute('srcset', i.getAttribute('data-srcset'));
    }

    if (i.naturalWidth && i.complete) {
      hidePlaceholder(i);
    }
  });

  YoutubeAPI.init();
}

document.onreadystatechange = () => {
  if (document.readyState === 'complete') {
    Millten();
    hideLoader();
  }
};

window.addEventListener('resize', debounce(loadScroller, 200));
window.addEventListener('beforeunload', (e) =>
  document.documentElement.classList.add('unloading')
);

window.onload = deferLoading;
